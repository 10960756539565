
import { IResponse } from '@/common/http'
import { ElMessage } from 'element-plus'
import { defineComponent, getCurrentInstance, onUnmounted, reactive, toRefs } from 'vue'
import { useRoute, useRouter } from 'vue-router'
interface IState {
  // eslint-disable-next-line camelcase
  payment: string | (string | null)[] | null,
  qrcode?: string,
  initLoad: boolean,
  name: string | null,
  price: number,
  timer?: number
}
export default defineComponent({
  name: 'Pay',
  setup () {
    const route = useRoute()
    const router = useRouter()
    const { proxy }: any = getCurrentInstance()
    const state = reactive<IState>({
      payment: null,
      initLoad: false,
      qrcode: undefined,
      name: null,
      price: 0,
      timer: undefined
    })
    const stateAsRefs = toRefs<IState>(state)

    const getTableList = (): void => {
      const query: any = route.query
      if (query) {
        proxy.$axios.post('/item/pay', route.query)
          .then((res: IResponse) => {
            if (res.code === 1) {
              state.payment = query.payment
              state.qrcode = res.data.qrcode
              state.name = decodeURIComponent(query.name)
              state.price = query.price
              checkPayStatus()
            } else {
              ElMessage.warning({
                message: res.msg,
                type: 'warning'
              })
              setTimeout(() => {
                router.push({ name: 'Cost' })
              }, 200)
            }
            setTimeout(() => {
              state.initLoad = true
            }, 500)
          })
      } else {
        ElMessage.warning({
          message: '表单数据不存在',
          type: 'warning'
        })
        setTimeout(() => {
          router.push({ name: 'Cost' })
        }, 200)
      }
    }
    getTableList()

    const checkPayStatus = (): void => {
      proxy.$axios.get('/fee/checkState', {
        params: {
          out_trade_no: route.query.out_trade_no
        }
      }).then((res: IResponse) => {
        if (res.code === 1) {
          if (res.data.state < 0) {
            if (state.timer) {
              clearTimeout(state.timer)
            }
            state.timer = window.setTimeout(() => {
              checkPayStatus()
            }, 5000)
          } else {
            ElMessage.success({
              message: '支付成功',
              type: 'success'
            })
            setTimeout(() => {
              router.push({ name: 'Shorten' })
            }, 800)
          }
        } else {
          ElMessage.warning({
            message: res.msg,
            type: 'warning'
          })
          setTimeout(() => {
            router.push({ name: 'Cost' })
          }, 200)
        }
      })
    }

    onUnmounted(() => {
      if (state.timer) {
        clearTimeout(state.timer)
      }
    })

    return {
      ...stateAsRefs
    }
  }
})
